import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Carousel } from '@johnlewispartnership/wtr-ingredients/ingredients/Carousel';
import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { ProductPod } from 'components/ProductPod2';

import { makeRecommendedCarouselContext } from 'utils/clickContext/recommendedCarouselContext';
import RichText from 'components/RichText';
import styles from './RecommendedCarousel.scss';

export default function RecommendedCarousel({
  titleDesktop,
  titleMobileAndTablet,
  viewAllLink,
  viewAllTitle,
  notifyViewAllClick,
  onSlideView,
  subTitle,
  products,
  className,
  ariaName,
  nameForAnalytics,
  typeForAnalytics,
  green,
}) {
  const clickContext = makeRecommendedCarouselContext({
    name: nameForAnalytics || ariaName || titleDesktop,
    type: typeForAnalytics,
  });

  return (
    <div
      className={classNames(
        className,
        styles.recommendedCarousel,
        { [styles.compact]: !green },
        { [styles.greenBackground]: green },
      )}
      data-testid="rec-prods"
    >
      <Typography
        styleAs="sectionHeadingSmall"
        element="h2"
        className={green ? styles.greenTitleTextMobileAndTablet : styles.mobileAndTabletText}
      >
        {typeof titleMobileAndTablet === 'string' ? (
          titleMobileAndTablet
        ) : (
          <RichText data={titleMobileAndTablet} />
        )}
      </Typography>
      <Typography
        styleAs="screenHeadingSmall"
        element="h2"
        className={green ? styles.greenTitleTextDesktop : undefined}
      >
        {typeof titleDesktop === 'string' ? titleDesktop : <RichText data={titleDesktop} />}
      </Typography>
      {subTitle && (
        <Typography styleAs="paragraphHeading" element="h3">
          {typeof subTitle === 'string' ? subTitle : <RichText data={subTitle} />}
        </Typography>
      )}
      <Carousel ariaName={ariaName} backgroundColor={green ? 'corporateGreen' : undefined}>
        {products.map(({ searchProduct, sponsored }, i) => (
          <ProductPod
            key={searchProduct}
            lineNumber={searchProduct}
            position={i + 1}
            sponsored={sponsored}
            clickContext={clickContext}
            imageLoading={i >= 4 ? 'lazy' : null}
          />
        ))}
      </Carousel>
      {!!viewAllLink && (
        <Typography
          component={TextLink}
          href={viewAllLink}
          onClick={notifyViewAllClick}
          light
          styleAs="paragraphHeading"
          className={classNames(className, styles.viewAllLink)}
        >
          {viewAllTitle}
        </Typography>
      )}
    </div>
  );
}

RecommendedCarousel.displayName = 'RecommendedCarousel';

RecommendedCarousel.propTypes = {
  titleDesktop: PropTypes.string.isRequired,
  titleMobileAndTablet: PropTypes.string.isRequired,
  viewAllLink: PropTypes.string,
  viewAllTitle: PropTypes.string,
  notifyViewAllClick: PropTypes.func,
  onSlideView: PropTypes.func,
  subTitle: PropTypes.string,
  ariaName: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      searchProduct: PropTypes.string,
      position: PropTypes.number,
      sponsored: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
  nameForAnalytics: PropTypes.string,
  typeForAnalytics: PropTypes.string,
  green: PropTypes.bool,
};

RecommendedCarousel.defaultProps = {
  notifyViewAllClick: () => {},
  onSlideView: undefined,
  viewAllLink: undefined,
  viewAllTitle: undefined,
  products: [],
  subTitle: undefined,
  ariaName: undefined,
  className: undefined,
  nameForAnalytics: undefined,
  typeForAnalytics: undefined,
  green: false,
};
